import React, { useEffect, useState } from 'react'
import style from './ManageProfile.module.scss'
// import '../../style/roleContianer.scss'
import {
  AuthService,
  // RoleService,
  UserService,
  PermissionService,
  UserType
} from '@digitalworkflow/dwloginclient'
import LoginPortalGrid from '../LoginPortalGrid/LoginPortalGrid'
interface Props {
  userInfo: UserType | null
  isAllowedToModify?: boolean
  isReadOnlyAccess: boolean
}

const ProjectEnabled = ({ userInfo }: Props) => {
  const [userProjectEnabled, setUserProjectEnabled] = useState<
    { project_id: string }[]
  >([])

  const authService = AuthService.instance()
  // RoleService.setAuthServiceInstance(authService)
  UserService.setAuthServiceInstance(authService)
  PermissionService.setAuthServiceInstance(authService)

  useEffect(() => {
    const projectIds = userInfo?.projects_enabled.map((item) => ({
      project_id: item
    }))
    setUserProjectEnabled(projectIds || [])
  }, [])

  const columnDefs = [
    {
      field: 'project_id',
      headerName: 'Project ID',
      sortable: true,
      unSortIcon: true,
      resizable: true,
      filter: 'agTextColumnFilter',
      minWidth: 200,
      flex: 2,
      floatingFilter: true
    }
  ]

  return (
    <div className={style.innerContainerWithButtons}>
      <div className={style.gridPortalContainer}>
        <LoginPortalGrid columnDefs={columnDefs} rowData={userProjectEnabled} />
      </div>
    </div>
  )
}
export default ProjectEnabled
