import { PSL } from '@digitalworkflow/psl_web'
import { LocalSettings } from './LocalSettings'
import { UserFilter } from '@digitalworkflow/dwloginclient/lib/Types/UserType'

export const exportData = async (
  urlPath: string,
  filename: string,
  filter?: UserFilter
) => {
  const axios = await PSL.GetAxiosInstance('login')

  if (!axios) {
    throw new Error('Axios instance not found')
  }

  axios.defaults.headers.common['X-Auth-Token'] =
    LocalSettings.getAuthToken() ?? ''

  try {
    const queries: any[] = []

    // Check if filter exists, then build query parameters
    if (filter) {
      const name = filter.name
      const email = filter.email
      const last_login = filter.last_login
      const role = filter.roles ? filter.roles.map((el) => `roles=${el}`) : []
      const workgroup = filter.workgroups
        ? filter.workgroups.map((el) => `work_groups=${el}`)
        : []
      const portals = filter.portals
        ? filter.portals.map((el) => `portals_enabled=${el}`)
        : []
      const status = filter.status

      if (name) queries.push(`name=${name}`)
      if (email) queries.push(`email=${email}`)
      if (last_login) queries.push(`last_login=${last_login}`)
      if (role.length > 0) queries.push(role.join('&'))
      if (workgroup.length > 0) queries.push(workgroup.join('&'))
      if (portals.length > 0) queries.push(portals.join('&'))
      if (status === true || status === false) queries.push(`status=${status}`)
    }

    // Construct the full URL with query parameters
    const urlWithParams = `${urlPath}?${queries.join('&')}`
    const response = await axios.get(urlWithParams, {
      responseType: 'blob'
    })

    const blob = new Blob([response.data], {
      type: response.headers['content-type']
    })
    const link = document.createElement('a')
    const blobUrl = window.URL.createObjectURL(blob)
    link.href = blobUrl

    link.download = filename
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
    window.URL.revokeObjectURL(blobUrl)
  } catch (error) {
    console.error('Error fetching data:', error)
  }
}
