import { RoleType, WorkgroupType } from '@digitalworkflow/dwloginclient'

export const sortArray = (array: any[], key: any) => {
  return [...array].sort((a: any, b: any) => {
    const valueA = a[key]
      ? a[key]
          .toString()
          .trim()
          .toLowerCase()
          .replace(/[^\p{L}\p{N}]/gu, '')
      : ''
    const valueB = b[key]
      ? b[key]
          .toString()
          .trim()
          .toLowerCase()
          .replace(/[^\p{L}\p{N}]/gu, '')
      : ''

    if (valueA < valueB) return -1
    if (valueA > valueB) return 1
    return 0
  })
}

const parseValues = (str: string | undefined): (string | number)[] => {
  if (typeof str !== 'string') {
    return []
  }
  return str
    .split('::')
    .map((value) =>
      value ? (isNaN(Number(value)) ? value.trim() : Number(value)) : ''
    )
}

// Sorting the array bv "Major :: Minor :: Section :: Part"
export const sortArrayByEveryPart =
  (key: string | null) =>
  (
    a: WorkgroupType | RoleType | string,
    b: WorkgroupType | RoleType | string
  ) => {
    const isObjectWithKey = (
      obj: any,
      key: string
    ): obj is Record<string, string> =>
      obj && typeof obj === 'object' && key in obj

    const [aMajor, aMinor, aSection, aPart] = parseValues(
      key && isObjectWithKey(a, key) ? a[key] : (a as string)
    )
    const [bMajor, bMinor, bSection, bPart] = parseValues(
      key && isObjectWithKey(b, key) ? b[key] : (b as string)
    )
    // Custom comparison logic for each level
    if (aMajor !== bMajor) return aMajor < bMajor ? -1 : 1
    if (aMinor !== bMinor) return aMinor < bMinor ? -1 : 1
    if (aSection !== bSection) return aSection < bSection ? -1 : 1
    if (aPart !== bPart) return aPart < bPart ? -1 : 1

    return 0
  }

// Custom sort comparator for AgGrid that removes leading spaces and converts values to lowercase for comparison.
export const sortComparator = (key: string | null) => (a: any, b: any) => {
  const cleanValue = (val: any) =>
    val
      ?.toString()
      .trim()
      .toLowerCase()
      .replace(/[^\p{L}\p{N}]/gu, '') || ''

  if (key) {
    const valueA = cleanValue(a?.[key])
    const valueB = cleanValue(b?.[key])

    if (valueA < valueB) return -1
    if (valueA > valueB) return 1
    return 0
  }

  const valueA = cleanValue(a)
  const valueB = cleanValue(b)

  if (valueA < valueB) return -1
  if (valueA > valueB) return 1
  return 0
}
